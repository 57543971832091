<template>
    <div class="row__start row__start--stretch company__row mt-15">
        <div class="row__start--flex1">
            <div v-if="oneCompany" class="h100 w-100 mb-60">
                <div
                    class="general__box"
                    :class="
                        $route.query.app == 1 || oneCompany.images.length != 0
                            ? ''
                            : 'vanish'
                    "
                    v-if="oneCompany.aboutUs || $route.query.app == 1"
                >
                    <h3 class="text__bold">Über uns</h3>
                    <p class="mt-15">{{ oneCompany.aboutUs }}</p>
                </div>

                <div class="general__box" v-if="oneCompany.offers.length > 0">
                    <h3 class="text__bold mb-15">Unsere Angebote</h3>
                    <p v-for="(offer, index) in oneCompany.offers" :key="index">
                        -
                        {{ offer.name }}
                    </p>
                </div>
                <div class="general__box" v-if="oneCompany.offers.length > 0">
                    <h3 class="text__bold">
                        Duale Ausbildung / unsere freien Ausbildungsplätze
                    </h3>
                </div>
                <div
                    class="general__box"
                    v-for="(apprenticeship,
                    index) in oneCompany.allApprenticeships"
                    :key="index"
                >
                    <h3 class=" mb-15">{{ apprenticeship.name }}</h3>
                    <!-- <h5 v-if="apprenticeship.number">
						Anzahl der Stellen: {{ apprenticeship.number }}
					</h5>
					<h5 v-else>Keine Stellenanzahl angegeben</h5> -->

                    <div class="general__box--cardbox">
                        <div
                            class="general__box--card"
                            v-if="apprenticeship.city"
                        >
                            {{ apprenticeship.city }}
                        </div>
                        <div
                            class="general__box--card"
                            v-for="(occupation,
                            secondIndex) in apprenticeship.occupations"
                            :key="secondIndex"
                        >
                            {{ occupation.name }}
                        </div>

                        <div
                            class="general__box--card"
                            v-if="apprenticeship.offer"
                        >
                            {{ apprenticeship.offer.name }}
                        </div>
                        <div
                            class="general__box--card"
                            v-if="apprenticeship.year"
                        >
                            {{ apprenticeship.year }}
                        </div>
                        <div
                            class="general__box--card"
                            v-if="apprenticeship.number"
                        >
                            <p v-if="apprenticeship.number == 1">
                                Stelle: {{ apprenticeship.number }}
                            </p>
                            <p v-if="apprenticeship.number > 1">
                                Stellen: {{ apprenticeship.number }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row__start--flex3 row__flex3--w43 mb-60">
            <!-- <div
				class="appointmentBox mb-15"
				style="max-height: 30vh"
				v-if="!$route.query.app == 1 && oneCompany.images.length == 0"
			>
				<h1>Termin vereinbaren</h1>
				<p
					class="mt-15 mb-60"
					v-if="oneCompany && oneCompany.hasWorkshops == true"
				>
					Um einen Termin mit uns zu buchen, stehen Dir an
					unterschiedlichen Tagen und Zeiträume verschiedene
					Ansprechpartner zur Verfügung. Du kannst wählen ob Du per
					Telefon, Videomeeting oder Chat mit uns kommunizieren
					möchtest. <br />Wir freuen uns auf Deine Terminbuchung (15
					Minuten pro Termin) und Deine anschließende Kontaktaufnahme
					über die Daten, die Dir per E-Mail nach der Buchung
					mitgeteilt werden.
				</p>
				<p
					class="mt-15 mb-60"
					v-if="oneCompany && oneCompany.hasWorkshops == false"
				>
					Bitte über unsere allgemeinen Kontaktdaten einen Termin
					vereinbaren.
				</p>
				<div
					class="absoluteBox absoluteBox--right"
					style="display:flex;"
					v-if="oneCompany && oneCompany.hasWorkshops == true"
				>
					<a
						class="button button--big"
						@click.stop="openAppointmentModal({ id })"
					>
						<p class="text__white">Jetzt Termin buchen</p>
					</a>
				</div>
			</div> -->
            <company-appointment-modal
                :company_id="id"
                :showModal="showAppointmentModal"
                @close="showAppointmentModal = false"
            />
            <div v-if="oneCompany" class="h100 ml-30">
                <div class="general__box" v-if="oneCompany.howToApply">
                    <h3 class="text__bold">So kannst Du Dich bewerben</h3>
                    <p class="mt-15">{{ oneCompany.howToApply }}</p>
                </div>
                <div class="general__box" v-if="oneCompany.facts">
                    <h3 class="text__bold">Schon gewusst?</h3>
                    <p class="mt-15">{{ oneCompany.facts }}</p>
                </div>

                <div
                    class="general__box"
                    v-if="oneCompany.info || oneCompany.mainContact"
                >
                    <h3 class="text__bold" v-if="oneCompany.mainContact">
                        Hauptansprechpartner/-in des Unternehmens
                    </h3>
                    <div
                        class="mt-15 mb-40 row__start row__responsive--wrap"
                        v-if="oneCompany.mainContact"
                    >
                        <div
                            class="contact__box mt-15"
                            v-if="oneCompany.mainContact.image"
                        >
                            <img :src="oneCompany.mainContact.image" alt="" />
                        </div>
                        <div
                            class="column__start mt-10 "
                            :class="
                                oneCompany.mainContact.image
                                    ? 'ml-25 responsive-no-ml'
                                    : ''
                            "
                        >
                            <h2 class="text__bold text__black m-0">
                                {{
                                    oneCompany.mainContact.name
                                        ? oneCompany.mainContact.name
                                        : "Kontakt"
                                }}
                            </h2>
                            <p v-if="oneCompany.mainContact.jobTitle">
                                {{ oneCompany.mainContact.jobTitle }}
                            </p>
                            <a
                                class="row__start mt-15"
                                v-if="oneCompany.mainContact.mail"
                                :href="'mailto:' + oneCompany.mainContact.mail"
                            >
                                <div class="w-40">
                                    <MailSvg />
                                </div>
                                <p>{{ oneCompany.mainContact.mail }}</p>
                            </a>
                            <a
                                class="row__start  "
                                :class="
                                    !oneCompany.mainContact.mail
                                        ? 'mt-15'
                                        : 'mt-4p'
                                "
                                v-if="oneCompany.mainContact.phone"
                                :href="'tel:' + oneCompany.mainContact.phone"
                            >
                                <div class="w-40">
                                    <PhoneSvg />
                                </div>
                                <p>{{ oneCompany.mainContact.phone }}</p>
                            </a>
                        </div>
                    </div>

                    <h3 class="text__bold" v-if="oneCompany.info">
                        Allgemeine Kontaktdaten
                    </h3>
                    <div class="mt-15 " v-if="oneCompany.info">
                        <div
                            class="mr-25"
                            v-if="
                                oneCompany.info.name ||
                                    oneCompany.info.street ||
                                    oneCompany.info.postalcode ||
                                    oneCompany.info.place
                            "
                        >
                            <p v-if="oneCompany.info.name" class="mt-4p">
                                {{ oneCompany.info.name }}
                            </p>
                            <p v-if="oneCompany.info.street" class="mt-4p">
                                {{ oneCompany.info.street }}
                            </p>
                            <p
                                v-if="
                                    oneCompany.info.postalcode ||
                                        oneCompany.info.place
                                "
                                class="mt-4p"
                            >
                                {{
                                    !oneCompany.info.place
                                        ? oneCompany.info.postalcode + " "
                                        : oneCompany.info.postalcode +
                                          " " +
                                          oneCompany.info.place
                                }}
                            </p>
                        </div>
                        <div>
                            <a
                                :href="'mailto:' + oneCompany.info.mail"
                                class="row__start mt-4p"
                                v-if="oneCompany.info.mail"
                            >
                                <div class="w-40">
                                    <MailSvg />
                                </div>
                                <p>{{ oneCompany.info.mail }}</p>
                            </a>
                            <a
                                class="row__start mt-4p"
                                v-if="oneCompany.info.phone"
                                :href="'tel:' + oneCompany.info.phone"
                            >
                                <div class="w-40">
                                    <PhoneSvg />
                                </div>
                                <p>{{ oneCompany.info.phone }}</p>
                            </a>
                            <a
                                class="row__start mt-4p"
                                v-if="oneCompany.info.website"
                                :href="
                                    checkCompanyWebsite(oneCompany.info.website)
                                "
                                target="_blank"
                            >
                                <div class="w-40">
                                    <EarthSvg />
                                </div>
                                <p>{{ oneCompany.info.website }}</p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CompanyAppointmentModal from "../components/CompanyAppointmentModal/Modal";
export default {
    name: "CompanyContent",
    components: { CompanyAppointmentModal },
    props: ["id", "showAbout"],
    data() {
        return {
            showAppointmentModal: false,
        };
    },
    methods: {
        ...mapActions(["getCompanyChatTypes", "getOneCompany"]),
        checkCompanyWebsite(link) {
            //adds https if link doesnt start with it
            var prefix = "http";
            if (link.substr(0, prefix.length) !== prefix) {
                link = "https://" + link;
            }
            let url = new URL(link);
            return url;
        },
        async openAppointmentModal({ id }) {
            let target = event.target;
            if (target.classList.contains("button")) {
                target = target.childNodes[0];
            }
            target.innerHTML = "<div class='loading'></div>";

            await this.getCompanyChatTypes({ id });
            target.innerHTML = "Jetzt Termin buchen";
            this.showAppointmentModal = true;
        },
    },
    created() {
        // this.getOneCompany({ id: this.id });
    },
    computed: {
        ...mapGetters(["oneCompany"]),
    },
    mounted() {},
};
</script>

<style scoped>
.appointmentBox {
    min-height: 0;
}
@media screen and (min-width: 1100px) {
    .vanish {
        display: none;
    }
}
</style>
