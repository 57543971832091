<template>
    <div class="navbar row__spacebetween main__padding">
        <div class="row__spacebetween w-100 cursor-pointer">
            <a class="logo--svg" href="https://www.ausbildungschance-owl.de/">
                <!-- <a class="logo--svg"> -->
                <LogoSvg />
            </a>
            <div class="row__spacebetween navbar--row m-15 for__navbar--big">
                <a
                    class="navbar--link cursor-pointer"
                    v-for="route in navbarRoutes"
                    :key="route.id"
                    :href="route.link"
                    >{{ route.name }}</a
                >
            </div>
            <!-- <a class="contact-button row__center for__navbar--big">
                <p class="text__white small">Kontakt</p>
            </a> -->
            <div
                class="for__navbar--small pointer"
                @click="navbarActive = !navbarActive"
            >
                <BurgerMenu :active="navbarActive" />
            </div>
        </div>
        <div
            class="navbar__dropdown"
            :class="{ 'navbar__dropdown--active': navbarActive }"
        >
            <a
                v-for="route in navbarRoutes"
                :key="route.id"
                :href="route.link"
                >{{ route.name }}</a
            >
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
    name: "Navbar",
    data() {
        return {
            navbarActive: false,
            navbarRoutes: [
                {
                    id: 1,
                    name: "Unternehmenslandkarte",
                    link: "https://unternehmen.ausbildungschance-owl.de/",
                },
                {
                    id: 4,
                    name: "Teilnehmende Unternehmen",
                    link:
                        "https://unternehmen.ausbildungschance-owl.de/participatingCompanies",
                },
                {
                    id: 5,
                    name: "Für Unternehmen",
                    link:
                        "http://www.ausbildungschance-owl.de/fuer-unternehmen/",
                },
                {
                    id: 6,
                    name: "Für Bewerberinnen und Bewerber",
                    link:
                        "https://www.ausbildungschance-owl.de/bewerberinnen-bewerber/",
                },
            ],
        };
    },
    methods: {
        ...mapActions([]),
    },
    created() {},
    computed: {},
    mounted() {},
};
</script>
