<template>
    <div class="checkBox__select row__spacebetween" @click="$emit('click')">
        <p :class="{ 'text-xl': textBig }">{{ text }}</p>
        <div class="checkBox__select--box">
            <CheckSvg :class="{ active: active }" />
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
    name: "checkBox",
    props: ["active", "text", "textBig"],
    data() {
        return {};
    },
    methods: {
        ...mapActions([]),
    },
    created() {},
    computed: {
        ...mapGetters([]),
    },
    mounted() {},
};
</script>
