import Vue from "vue";
import VueRouter from "vue-router";
// Company
Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Home",
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/Home.vue"),
	},
	{
		path: "/login",
		name: "Login",
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/Login.vue"),
	},
	{
		path: "/register",
		name: "Register",
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/Register.vue"),
	},
	{
		path: "/password",
		name: "Password",
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/Password.vue"),
	},
	{
		path: "/password/reset",
		name: "Reset",
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/Reset.vue"),
	},
	{
		path: "/calendar",
		name: "Calendar",
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/Calendar.vue"),
	},
	{
		path: "/settings",
		name: "Settings",
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/Settings.vue"),
		meta: { isLoggedIn: true },
	},
	{
		path: "/contact",
		name: "Contact",
		component: () =>
			import(
				/* webpackChunkName: "info" */ "../views/informations/Contact.vue"
			),
	},
	{
		path: "/imprint",
		name: "Imprint",
		component: () =>
			import(
				/* webpackChunkName: "info" */ "../views/informations/Imprint.vue"
			),
	},
	{
		path: "/privacy",
		name: "Privacy",
		component: () =>
			import(
				/* webpackChunkName: "info" */ "../views/informations/Privacy.vue"
			),
	},
	{
		path: "/forStudents",
		name: "ForStudents",
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/ForStudents.vue"),
	},
	{
		path: "/forCompanies",
		name: "ForCompanies",
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/ForCompanies.vue"),
	},
	{
		path: "/faq",
		name: "FAQ",
		component: () => import(/* webpackChunkName: "about" */ "../views/FAQ.vue"),
	},
	{
		path: "/instructionInterview",
		name: "InstructionInterview",
		component: () =>
			import(
				/* webpackChunkName: "about" */ "../views/InstructionInterview.vue"
			),
	},
	{
		path: "/participatingCompanies",
		name: "ParticipatingCompanies",
		component: () =>
			import(
				/* webpackChunkName: "about" */ "../views/ParticipatingCompanies.vue"
			),
	},
	{
		path: "/company/:id/:name",
		name: "Company",
		props: true,
		component: () => import("../views/Company.vue"),
	},
	{
		path: "/chat/:id",
		name: "Chat",
		props: true,
		component: () => import("../views/Chat.vue"),
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});
// router.beforeEach(async (to, from, next) => {
//     // const isAdmin = true;
//     const isLoggedIn = localStorage.getItem("token")
//     console.log("test");
//     // const routeRequiresAdmin = to.matched.some(
//     //     (record) => record.meta.requiresAdmin
//     // );
//     const routeRequiresLogIn = to.matched.some(
//         (record) => record.meta.isLoggedIn
//     );
//     if (to.name == "TEst") {
//         console.log("test");
//     } else if (!isLoggedIn && routeRequiresLogIn) {
//         next({ name: "Login" });
//         return;
//     } else if (isLoggedIn && !routeRequiresLogIn) {
//         next({ name: "Home" });
//     }
//     next();
// });

export default router;
