<template>
	<div class="row__spacebetween w-100 login-container">
		<img
			class="max-w-100 login-icon"
			src="../../assets/images/appointmentLoginIcon.png"
		/>
		<div class="login-box ">
			<div class="header">Anmelden</div>
			<div class="searchbox searchbox--primary-border row__start mb-25">
				<input
					type="text"
					v-model="email"
					placeholder="Email"
					@input="errorMessage = null"
				/>
			</div>

			<div class="searchbox searchbox--primary-border row__start mb-35">
				<input
					:type="seePassword ? 'text' : 'password'"
					v-model="password"
					placeholder="Passwort"
					autocomplete
					@input="errorMessage = null"
				/>
				<div @click="seePassword = !seePassword">
					<SeeSvg
						:classes="seePassword ? 'svg__fill--primary' : 'svg__fill--grey700'"
					/>
				</div>
			</div>
			<div class="mb-20 color-error -mt-10" v-if="errorMessage">
				{{ errorMessage }}
			</div>
			<div v-if="isLoginSucceeded">
				<p class="text__primary mb-15 -mt-10 ml-25">
					Die Anmeldung war erfolgreich <CheckSvg class="ml-10" />
				</p>
			</div>
			<div class="row__spacebetween">
				<div class="button mr-15" @click="handleLogin">
					<p class="text__white">
						Anmelden
					</p>
				</div>
				<div
					v-if="$route.query.app != 1"
					class="button button--reverse"
					@click.stop="registerCustomer"
				>
					<p class="text__primary text__semibold">
						Registrieren
					</p>
				</div>
				<div
					v-else-if="$route.query.app == 1"
					class="button button--reverse"
					@click.stop="showRegisterModal = true"
				>
					<p class="text__primary text__semibold">
						Registrieren
					</p>
				</div>
			</div>
		</div>

		<img
			class="max-w-100 "
			src="../../assets/images/appointmentLoginPerson.png"
		/>
		<register-modal
			:showModal="showRegisterModal"
			@close="showRegisterModal = false"
		/>
	</div>
</template>

<script>
import { mapActions } from "vuex";
import RegisterModal from "./RegisterModal.vue";
export default {
	components: { RegisterModal },
	name: "AppointmentLogin",
	data() {
		return {
			email: "",
			password: "",
			seePassword: false,
			errorMessage: null,
			isLoading: false,
			isLoginSucceeded: false,

			showRegisterModal: false,
		};
	},
	emits: ["change"],
	methods: {
		...mapActions(["loginUser"]),
		registerCustomer() {
			let routeData = this.$router.resolve({ name: "Register" });
			window.open(routeData.href, "_blank");
		},
		async handleLogin(event) {
			if (this.isLoading) {
				return;
			}
			let target = event.target;
			if (target.classList.contains("button")) {
				target = target.childNodes[0];
			}
			if (this.isLoading) {
				return;
			}
			this.isLoading = true;
			target.innerHTML = "<div class='loading'></div>";

			let loginSucceeded = await this.loginUser({
				email: this.email,
				password: this.password,
			});
			console.log(loginSucceeded);
			if (loginSucceeded === true) {
				this.$emit("change");
				this.isLoginSucceeded = true;
			} else {
				this.errorMessage = loginSucceeded;
			}
			//this.$emit("change");
			target.innerHTML = "Anmelden";
			this.isLoading = false;
		},
	},
	created() {},
	computed: {},
};
</script>
<style lang="scss" scoped>
.login-icon {
	align-self: flex-start;
	margin-top: 20px;
}
.login-container {
	img {
		max-width: calc(50% - 210px);
	}
	@media only screen and (max-width: 750px) {
		img {
			display: none;
		}
		justify-content: center;
	}
	.color-error {
		color: red;
	}
	.-mt-10 {
		margin-top: -10px;
	}
}
.login-box {
	width: 320px;
	max-width: calc(95vw - 40px);
	padding: 20px 20px 20px 30px;
	margin: 0 30px 10px 30px;
	border-radius: 19px;
	box-shadow: 0 3px 6px rgba(#000000, 0.16);
	.header {
		padding-bottom: 15px;
		width: 100%;
		text-align: center;
		font-size: 30px;
		font-weight: 500;
		color: #000;
		border-bottom: 1px solid #efefef;
		margin-bottom: 25px;
	}
}
</style>
