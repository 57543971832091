<template>
    <div class="row__start row__start--wrap">
        <div v-for="(option, key) of filter" :key="key">
            <div
                class="row__start row__start--wrap"
                v-if="key == 'selectedDistricts'"
            >
                <div
                    v-for="(district, index) in option"
                    :key="index"
                    class="remove__filter--box"
                    @click="
                        () => {
                            filter.selectedDistricts = filter.selectedDistricts.filter(
                                (d) => d != district
                            );
                        }
                    "
                >
                    <p>
                        {{ district }}
                    </p>
                    <div class="remove__filter--svg">
                        <CancelSvg />
                    </div>
                </div>
            </div>
            <div
                class="remove__filter--box"
                v-else-if="
                    (typeof option == 'object' && option && option.name) ||
                        (typeof option != 'object' && option)
                "
                @click="
                    () => {
                        if (typeof option == 'object') {
                            filter[key] = {};
                        } else {
                            filter[key] = null;
                        }
                    }
                "
            >
                <p>
                    {{
                        typeof option == "object" && option
                            ? option.name.trim()
                            : option
                    }}
                </p>
                <div class="remove__filter--svg">
                    <CancelSvg />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    name: "Home",
    data() {
        return {
            // filter: {
            //     selectedOccupation: {},
            //     selectedApprenticeship: {},
            //     selectedOffer: {},
            //     selectedDistricts: [null],
            //     selectedPlace: null,
            //     selectedCompany: {},
            //     companyName: null,
            //     selectedYear: null,
            // },
        };
    },
    props: ["filter"],
    methods: {},

    computed: {
        chooseFilter() {
            let filter = [];
            // if(){

            // }
            return filter;
        },
    },
};
</script>
<style lang="scss" scoped>
@import "../assets/scss/_variables.scss";
.remove__filter--box {
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    background-color: $primary;
    cursor: pointer;
    margin: 15px 18px 0 0;
    p {
        font-weight: 16px;
        white-space: nowrap;
        color: $white;
        padding: 8px 7px 8px 15px;
        border-right: 1px solid #04316c;
    }
    .remove__filter--svg {
        padding: 12px 5px 11px 4px;
    }
}
</style>
