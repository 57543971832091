<template>
    <div>
        <div class="header-3 pointer row__start" @click="isOpen = !isOpen">
            <div class="mr-20">
                <slot name="title"></slot>
            </div>
            <arrow-right-active-svg
                :style="isOpen ? 'transform: rotate(90deg);' : ''"
            ></arrow-right-active-svg>
        </div>
        <div v-show="isOpen">
            <p>
                <slot name="content"></slot>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isOpen: false,
        };
    },
};
</script>

<style></style>
