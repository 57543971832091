<template>
    <div class="footer">
        <div>
            <h2>Ein Kooperationsprojekt von:</h2>
            <div
                style="display: flex; margin-bottom: 25px"
                class="subContainer"
            >
                <logo-container :logo="'IHK'" />
                <logo-container :logo="'HWK'" />
                <logo-container :logo="'IHK_lip'" />
            </div>
        </div>
        <div v-if="$route.name == 'Home' && $route.query.app != 1">
            <h2>...und Projekte</h2>
            <div style="display: flex;">
                <div>
                    <h4>KAoA</h4>
                    <div
                        style="display: flex; margin-bottom: 25px"
                        class="subContainer"
                    >
                        <logo-container :logo="'KAOA'" />
                        <logo-container :logo="'ESG'" />
                    </div>
                </div>

                <div style="margin-left: 100px;">
                    <h4>Passgenaue Besetzung</h4>
                    <div
                        style="display: flex; margin-bottom: 25px"
                        class="subContainer"
                    >
                        <logo-container :logo="'PAB'" />
                        <logo-container :logo="'4LOG'" />
                    </div>
                </div>
            </div>
            <div style="display: flex;">
                <div>
                    <h4>Berufliche Bildungslotsen/Ausbildungsbotschafter</h4>
                    <div
                        style="display: flex; margin-bottom: 25px"
                        class="subContainer"
                    >
                        <logo-container :logo="'BIL'" />
                        <logo-container :logo="'KAOA'" />
                    </div>
                </div>
                <div style="margin-left: 100px;">
                    <h4>Passgenaue Besetzung - Willkommenslotsen</h4>
                    <div
                        style="display: flex; margin-bottom: 25px"
                        class="subContainer"
                    >
                        <logo-container :logo="'WILK'" />
                    </div>
                </div>
            </div>
        </div>
        <div class="row__spacebetween">
            <div>
                <TogetherForOccupation class="custom-svg" />
            </div>
            <div style="row-end display:flex;">
                <a
                    href="https://www.ausbildungschance-owl.de/ansprechpartner/"
                    style="margin-left: 25px; color: grey; cursor:pointer;"
                    >Kontakt
                </a>
                <a
                    href="https://www.ausbildungschance-owl.de/datenschutz/"
                    style="margin-left: 25px; color: grey; cursor:pointer;"
                    >Datenschutz
                </a>
                <a
                    href="https://www.ausbildungschance-owl.de/impressum/"
                    style="margin-left: 25px; color: grey; cursor:pointer;"
                    >Impressum
                </a>
            </div>
        </div>
        <div class="devider"></div>

        <div class="footer-text mb-50">
            <p class="mr-30 w-50">info@ausbildungschance-owl.de</p>
            <p>
                Azubi-Speed-Dating - Ausbildungschance OWL ist ein
                Kooperationsprojekt der Industrie und Handelskammer Ostwestfalen
                zu Bielefeld, der Handwerkskammer Ostwestfalen Lippe zu
                Bielefeld und der Industrie und Handelskammer Lippe zu Detmold.
                Azubi-Speed-Dating.app PRO ist eine Software der
                <a href="https://www.spieker.agency"
                    >spieker - marketing &amp; cloud agency. // spieker products
                    GmbH</a
                >
            </p>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import LogoContainer from "./FooterLogoContainer";
export default {
    components: { LogoContainer },
    name: "Footer",
    data() {
        return {};
    },
    methods: {
        ...mapActions([]),
    },
    created() {},
    computed: {},
    mounted() {},
};
</script>
<style lang="scss">
.custom-svg {
    margin: 7px 15px 0 0;
}
.footer-text {
    align-self: center;
    font-size: 16px;
    color: #a0a1a2;
    text-align: center;
    max-width: 1305px;
    display: flex;
    align-items: center;
    p {
        color: #a0a1a2;
    }
}
.partner {
    display: flex;
    flex-flow: wrap;
}
.secondContainer {
    margin-left: 30px;
}
@media only screen and (max-width: 768px) {
    .subContainer {
        flex-flow: wrap;
        width: 40vw;
    }
    .secondContainer {
        margin-left: 0%;
    }
    .footer-text {
        flex-direction: column;
        p {
            margin-bottom: 15px;
        }
    }
}
</style>
