<template>
    <div class="chat__box">
        <div
            id="feed"
            class="chat__box--scrollbox scrollbar scrollbar--dark"
            ref="scrollbox"
        >
            <div class="chat__information">
                Terminstart: {{ myChat.date }}{{ " " + myChat.time }}
            </div>
            <div v-for="message in myChat.chatMessages" :key="message.id">
                <div
                    v-if="message.status == 'system'"
                    class="chat__information"
                >
                    {{ message.message }}{{ " " + message.time }}
                </div>

                <div
                    v-else-if="myChat.contact == message.sender.name"
                    class="chat__message chat__message--extern"
                >
                    <div class="row__start mb-5">
                        <p class="text__primary text__semibold">
                            {{ message.sender.name }}
                        </p>
                        <p class="text__grey">{{ message.time }}</p>
                    </div>
                    <p>
                        {{ message.message }}
                    </p>
                </div>
                <div v-else class="chat__message chat__message--user">
                    <p class="text__white mb-5">{{ message.time }}</p>
                    <p class="text__white">
                        {{ message.message }}
                    </p>
                </div>
            </div>
        </div>
        <div class="chat__textarea">
            <textarea-autosize
                placeholder="Nachricht schreiben..."
                :min-height="20"
                :max-height="300"
                v-model="text"
                ref="textarea"
                @blur.native="onBlurTextarea"
                class="scrollbar"
            />

            <div class="chat__svg--box" @click="send()">
                <ChatSvg />
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Echo from "laravel-echo";
export default {
    data() {
        return {
            text: "",
        };
    },
    computed: {
        ...mapGetters(["myChat"]),
    },
    methods: {
        ...mapActions(["sendMessage", "addMessageToChat"]),
        onBlurTextarea() {
            // console.log(this.text);
        },
        scrollToBottom: function () {
            setTimeout(function () {
                var objDiv = document.getElementById("feed");
                objDiv.scrollTop = objDiv.scrollHeight - objDiv.clientHeight;
            }, 1000);
        },
        changeSize() {
            let scrollbox = this.$refs.scrollbox;
            let textarea = this.$refs.textarea.$el;
            scrollbox.style.height =
                "calc(100% - 15px - " + textarea.style.height + ")";
            scrollbox.scrollTop =
                scrollbox.scrollHeight - scrollbox.clientHeight + 10;
        },
        async send() {
            if (this.text !== "") {
                await this.sendMessage({
                    id: this.myChat.id,
                    message: this.text,
                    status: "message",
                    code: this.$route.query.code
                });
            }
            this.text = "";
            this.changeSize();
        },
    },
    watch: {
        text() {
            this.changeSize();
        },
    },
    async mounted() {
        this.scrollToBottom();
        window.Echo = new Echo({
            broadcaster: "pusher",
            key: window.PusherAppKey,
            cluster: "eu",
            encrypted: true,
            forceTLS: true,
            authEndpoint: window.base + "api/broadcasting/auth",
            auth: {
                headers: {
                    Authorization: "Bearer " + this.$store.state.auth.token,
                },
            },
        });

        var channel = window.Echo.private(
            "chat." + this.$router.currentRoute.params.id
        );
        channel.listen("NewMessage", async (data) => {
            if (data.message.sender.name == this.myChat.contact) {
                await this.myChat.chatMessages.push(data.message);
            }
            this.changeSize();
        });
    },
};
</script>

<style></style>
