<template>
	<div
		class="select"
		:class="
			selectActive && classes
				? classes + ' select--active'
				: classes
				? classes
				: ''
		"
	>
		<div class="select__box" v-click-outside="removeActive">
			<div class="select__box--input" @click.stop="setActive">
				<input
					v-if="readonly"
					class="select__input"
					type="text"
					:value="noName ? selectedItem : selectedItem.name"
					:placeholder="placeholder"
					readonly
				/>
				<input
					v-else
					class="select__input"
					type="text"
					v-model="selectedItem.name"
					:placeholder="placeholder"
				/>
				<SelectArrowSvg />
			</div>

			<div id="selectOptionsBox" class="select__options--box scrollbar">
				<p
					v-for="(option, index) in filteredOptions"
					:key="index"
					@click.stop="makeChangeEvent(option)"
					class="ph-10 p--selectitem"
					:class="
						noName
							? option == selectedItem
								? 'text__primary'
								: ''
							: selectedID == option.id
							? 'text__primary'
							: ''
					"
				>
					{{
						noName
							? option
								? option.trim()
								: ""
							: option.name
							? option.name.trim()
							: ""
					}}
				</p>
			</div>
		</div>
	</div>
</template>
<script>
	import ClickOutside from "vue-click-outside";
	export default {
		props: {
			noName: {
				type: Boolean,
				default: false,
			},
			options: {},
			placeholder: {},
			selectedItem: {},
			classes: {},
			autoscrollDown: {
				type: Boolean,
				default: false,
			},
			readonly: {
				type: Boolean,
				default: true,
			},
		},
		components: {},
		data() {
			return {
				selectedID: null,
				selectActive: false,
				filter: "",
			};
		},
		methods: {
			makeChangeEvent(option) {
				if (!this.noName) {
					this.selectedID = option.id;
				}
				this.$emit("change", option);
				this.selectActive = false;
			},
			setActive() {
				this.selectActive = !this.selectActive;
				if (this.autoscrollDown) {
					console.log("entered");
					setTimeout(() => {
						var modalBody = document.getElementById("modalBody");
						modalBody.scrollTop = modalBody.scrollHeight;
					}, 300);
				}
			},
			removeActive() {
				this.selectActive = false;
			},
		},
		computed: {
			filteredOptions() {
				let filteredOptions = this.options.filter((option) => {
					if (option.name) {
						if (this.selectedItem.name) {
							if (
								option.name
									.toLowerCase()
									.includes(
										this.selectedItem.name.toLowerCase()
									)
							) {
								return true;
							}
						} else {
							return true;
						}
					} else {
						return true;
					}
				});
				return filteredOptions;
			},
		},
		directives: {
			ClickOutside,
		},
		mounted() {
			// console.log(this.options);
		},
	};
</script>
