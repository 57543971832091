<template>
	<div
		class="navbar__user"
		:class="{ 'navbar__user--active': showNavbar }"
		@click="showNavbar = !showNavbar"
		ref="userNavbar"
	>
		<HumanSvg />

		<div class="user__links" :class="{ user__loggedin: token }">
			<router-link :to="{ name: 'Home' }" class="user__link">
				<div class="svg__box">
					<SearchSvg />
				</div>
				<div>Suche</div>
			</router-link>

			<router-link :to="{ name: 'Calendar' }" class="user__link" v-if="token">
				<div class="svg__box">
					<CalenderSvg />
				</div>
				<div>Meine Termine</div>
			</router-link>

			<router-link :to="{ name: 'Settings' }" class="user__link" v-if="token">
				<div class="svg__box">
					<SettingSvg />
				</div>
				<div>Einstellungen</div>
			</router-link>

			<router-link :to="{ name: 'Login' }" class="user__link" v-if="!token">
				<div class="svg__box"><LoginSvg /></div>
				<div>Anmelden</div>
			</router-link>

			<div @click="prepareLogout" class="user__link" v-if="token">
				<div class="svg__box">
					<LogoutSvg />
				</div>
				<div>Logout</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ClickOutside from "vue-click-outside";
export default {
	name: "NavbarUser",
	data() {
		return {
			showNavbar: true,
			links: [
				{ id: 1, name: "Home", auth: false },
				{ id: 2, name: "Calendar", auth: true },
				{ id: 3, name: "Settings", auth: true },
				{ id: 4, name: "Login", auth: true },
				{ id: 5, name: "Logout", auth: true },
			],
		};
	},
	methods: {
		...mapActions(["logoutUser"]),
		async prepareLogout() {
			await this.logoutUser();
		},
		closeBox() {
			this.showNavbar = false;
		},
	},
	created() {},
	computed: {
		...mapGetters(["token"]),
	},
	mounted() {},
	directives: {
		ClickOutside,
	},
};
</script>
