<template>
	<div class="modal" v-if="showModal" @click.self="closeModal()">
		<div class="modal__box modal__small">
			<div
				style="height: 300px"
				:style="$route.query.app == 1 ? 'padding: 0 10px' : ''"
			>
				<h2>Achtung!</h2>
				<p>
					Wenn Sie Ihr Konto löschen, werden sämtliche Ihrer Termine
					storniert!
				</p>
				<br /><br />
				<p>
					Möchten Sie Ihre Konto wirklich löschen?
				</p>
			</div>
			<div class="modal__footer row_end">
				<div
					class="button button--reverse mr-25"
					@click="$emit('disagree')"
				>
					<p class="text__primary text__semibold">Abbrechen</p>
				</div>
				<div class="button mr-10" @click="$emit('agree')">
					<p class="text__white">
						Löschen
					</p>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import ClickOutside from "vue-click-outside";
	export default {
		props: ["showModal"],
		directives: {
			ClickOutside,
		},
		methods: {
			closeModal() {
				this.$emit("close");
			},
		},
	};
</script>
<style scoped>
	.modal__box {
		padding: 0 30px;
	}
	.modal__footer {
		margin-top: -5rem;
		background-color: white !important;
	}
</style>
